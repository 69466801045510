import React from "react"
import InnerPageHeroSection from "../components/common/InnerPageHeroSection"
import SmallBusinessSection from "../components/pages/SmallBusinesses/SmallBusinessSection"
import FeaturesWithHoveredImageSection from "../components/common/FeaturesWithHoveredImageSection"
import BusinessByIndustrySection from "../components/pages/SmallBusinesses/BusinessByIndustrySection"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import MobileAppLinksSection from "../components/common/MobileAppLinksSection"
import SecurityCardsSection from "../components/common/SecurityCardsSection"
import SolutionsSection from "../components/common/SolutionsSection"
import TestimonialsSection from "../components/common/TestimonialsSection"
import CaseStudySection from "../components/common/CaseStudySection"
import HappyCustomerSection from "../components/common/HappyCustomerSection"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  benefitsSection,
  businessByIndustrySection,
  caseStudiesSection,
  faqs,
  happyCustomerSection,
  heroSection,
  individualsSection,
  problemSection,
  smallBusinessSection,
  solutionsSection,
  testimonialSection,
} from "../Content/SmallBusiness"
import FaqsSection from "../components/common/FaqsSection"
import { businessModelsWithFormSection, mobileAppLinksSection } from "../Content/Common"
import HorizontalCardsScrollSection from "../components/common/HorizontalCardsScrollSection"

const SmallBusinesses = () => {
  return (
    <Layout>
      <Seo
        title="Effortless Financial Data Sharing with Accountants for Businesses"
        description={`With Synkli, businesses can securely share financial documents, simplifying collaboration with accountants and enhancing accounting practice management.`}
      />

      <InnerPageHeroSection
        title={heroSection.title}
        description={heroSection.description}
        image={{
          path: heroSection.image.path,
          alt: heroSection.image.alt,
        }}
      />

      <HorizontalCardsScrollSection
        title={problemSection.title}
        titleEC={`!max-w-[1200px]`}
        description={problemSection.description}
        topProblems={problemSection.topProblems}
        bottomProblems={problemSection.bottomProblems}
      />

      <SolutionsSection
        title={solutionsSection.title}
        description={solutionsSection.description}
        firstColumnSolutions={solutionsSection.firstColumnSolutions}
        secondColumnSolutions={solutionsSection.secondColumnSolutions}
        thirdColumnSolutions={solutionsSection.thirdColumnSolutions}
      />

      <SmallBusinessSection
        subTitle={smallBusinessSection.subTitle}
        title={smallBusinessSection.title}
        description={smallBusinessSection.description}
        CTATitle={smallBusinessSection.CTATitle}
        CTADescription={smallBusinessSection.CTADescription}
        CTAImage={{
          path: smallBusinessSection.CTAImage.path,
          alt: smallBusinessSection.CTAImage.alt,
        }}
        hoveredImage={{
          path: smallBusinessSection.hoveredImage.path,
          alt: smallBusinessSection.hoveredImage.alt
        }}
      />

      <SmallBusinessSection
        subTitle={individualsSection.subTitle}
        title={individualsSection.title}
        description={individualsSection.description}
        CTATitle={individualsSection.CTATitle}
        CTADescription={individualsSection.CTADescription}
        CTAImage={{
          path: individualsSection.CTAImage.path,
          alt: individualsSection.CTAImage.alt,
        }}
        hoveredImage={{
          path: individualsSection.hoveredImage.path,
          alt: individualsSection.hoveredImage.alt
        }}
        CTASectionEC={`flex-col md:flex-row-reverse`}
      />

      <FeaturesWithHoveredImageSection
        title={benefitsSection.title}
        description={benefitsSection.description}
        items={benefitsSection.benefits}
      />

      <BusinessByIndustrySection
        subTitle={businessByIndustrySection.subTitle}
        title={businessByIndustrySection.title}
        industries={businessByIndustrySection.industries}
      />

      <BusinessModelsWithFormSection
        title={businessModelsWithFormSection.title}
        description={businessModelsWithFormSection.description}
        formSubmitButtonText={
          businessModelsWithFormSection.formSubmitButtonText
        }
      />

      <div className="pt-1">
        <TestimonialsSection
          title={testimonialSection.title}
          description={testimonialSection.description}
        />
      </div>

      <CaseStudySection
        title={caseStudiesSection.title}
        description={caseStudiesSection.description}
      />

      <HappyCustomerSection
        subTitle={happyCustomerSection.subTitle}
        mainTitle={happyCustomerSection.mainTitle}
        title={happyCustomerSection.title}
        description={happyCustomerSection.description}
      />

      <FaqsSection faqs={faqs} />

      <MobileAppLinksSection
        title={mobileAppLinksSection.title}
        description={mobileAppLinksSection.description}
      />

      <SecurityCardsSection />
    </Layout>
  )
}

export default SmallBusinesses
