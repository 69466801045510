import React from "react"
import PageSectionHeader from "./PageSectionHeader"
import CTASectionWithImage from "../molecules/CTASectionWithImage"

const FeaturesWithHoveredImageSection = ({ title, description, items }) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={`BENEFITS`}
        title={title}
        titleEC={`max-w-[1200px]`}
        description={description}
        descriptionEC={`max-w-[800px] mx-auto`}
      />
      <div className="mt-16">
        {items.map((item, index) => (
          <CTASectionWithImage
            key={"item_" + index}
            componentEC={item.componentEC + " mt-24"}
            title={item.title}
            description={item.description}
            {...(item.buttonn && { button: item.buttonn })}
            image={{
              path: item.image.path,
              alt: item.image.alt,
            }}
            hoveredImage={{
              path: item.hoveredImage.path,
              alt: item.hoveredImage.alt
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default FeaturesWithHoveredImageSection
