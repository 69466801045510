import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import CTASectionWithImage from "../../molecules/CTASectionWithImage"

const SmallBusinessSection = ({subTitle, title, description, CTATitle, CTADescription, CTAImage, hoveredImage, CTASectionEC}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={subTitle}
        title={title}
        titleEC={`!max-w-[1200px] text-3xl sm:text-3xl md:text-4xl lg:text-5xl`}
        description={description}
        descriptionEC={`max-w-[1070px] mx-auto text-[16px] md:text-[18px]`}
      />
      <div className="md:pt-8">
        <CTASectionWithImage
          componentEC={CTASectionEC}
          title={CTATitle}
          description={CTADescription}
          image={{
            path: CTAImage.path,
            alt: CTAImage.alt,
          }}
          hoveredImage={{
            path: hoveredImage.path,
            alt: hoveredImage.alt
          }}
        />
      </div>
    </div>
  )
}

export default SmallBusinessSection
